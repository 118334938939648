import {
  Vehicle,
  VehicleName,
  VehicleNameAddAction,
  VehicleUpdateAction
} from '../type';
import { SET_VEHICLE_UPDATES, ADD_VEHICLE_NAMES } from './actionTypes';

export const setVehicleUpdates = (vehicles: Vehicle[]): VehicleUpdateAction => {
  return { type: SET_VEHICLE_UPDATES, vehicles };
};

export const addVehicleNames = (
  vehicleNames: VehicleName[]
): VehicleNameAddAction => {
  return { type: ADD_VEHICLE_NAMES, vehicleNames };
};
