import { VehicleNameState, VehicleNameAddAction } from '../../type';
import { ADD_VEHICLE_NAMES } from '../actionTypes';

const initialState: VehicleNameState = {};

export default function (state = initialState, action: VehicleNameAddAction) {
  switch (action.type) {
    case ADD_VEHICLE_NAMES: {
      action.vehicleNames.forEach(
        (vehicleName) => (state[vehicleName.may_id] = vehicleName.display_name)
      );
      return state;
    }
    default:
      return state;
  }
}
