import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useAppSelector } from '../redux/hooks';
import { clearDispatchVehicle, dispatchVehicle } from '../requests';
import { DispatchMode, RouteStop, TaskType, Vehicle } from '../type';
import { Auth } from 'aws-amplify';

const DISPATCH_BUTTON_TIMEOUT_MS = 5 * 1000;
const CANCEL_BUTTON_TIMEOUT_MS = 5 * 1000;

interface BottomSheetProps {
  mayId: string;
  selectedStop?: RouteStop;
  dispatchMode: DispatchMode;
}

enum BottomSheetButtonType {
  GO_HERE,
  PICKUP,
  DROPOFF
}

const BottomSheet = (props: BottomSheetProps) => {
  const { mayId, selectedStop, dispatchMode } = props;
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [disableDispatch, setDisableDispatch] = useState<boolean>(false);
  const [disableCancel, setDisableCancel] = useState<boolean>(false);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const session = await Auth.currentSession();
        setAccessToken(session.getAccessToken().getJwtToken());
      } catch (error) {
        console.log('Error fetching access token', error);
      }
    };
    fetchAccessToken();
  }, []);

  const vehicle: Vehicle | undefined = useAppSelector(
    (state) => state.vehicles[mayId]
  );

  const vehicleName: string = useAppSelector((state) =>
    //We should have the vehicle name because we selected the vehicle from the
    //home page and we cache the name even if the vehicle is no longer available.
    //Just handling this scenario by setting a default value.
    state.vehicleNames[mayId] !== undefined ? state.vehicleNames[mayId] : mayId
  );

  const getContent = () => {
    if (vehicle === undefined) {
      return `Unable to find vehicle with name ${vehicleName}`;
    } else if (vehicle.nextStop) {
      return `Dispatched to ${vehicle.nextStop}`;
    } else if (selectedStop === undefined) {
      return "Tap the stop you'd like to visit next";
    } else {
      return `Selected destination: ${selectedStop?.stop_point_id}`;
    }
  };

  const onCancelClick = async () => {
    if (vehicle === undefined) return;
    setDisableCancel(true);
    console.log('Cancelling dispatch...');
    const res = await clearDispatchVehicle(vehicle, accessToken);
    if (res.status === 200) {
      console.log('Cancelled dispatched vehicle...');
    } else {
      console.error(res.data);
      alert(JSON.stringify(res.data, null, 2));
    }
    setTimeout(() => setDisableCancel(false), CANCEL_BUTTON_TIMEOUT_MS);
  };

  const getButton = (
    expectedMode: DispatchMode,
    text: string,
    buttonType: BottomSheetButtonType
  ) => {
    if (
      vehicle === undefined ||
      vehicle.nextStop ||
      dispatchMode != expectedMode
    )
      return;

    const onClick = async () => {
      if (selectedStop === undefined || vehicle === undefined) return;

      setDisableDispatch(true);

      console.log('Dispatching...');

      const taskType =
        buttonType === BottomSheetButtonType.PICKUP
          ? TaskType.PICKUP
          : TaskType.DROPOFF;

      const res = await dispatchVehicle(
        vehicle,
        selectedStop,
        taskType,
        accessToken
      );

      if (res.status === 200) {
        console.log('Dispatched vehicle...');
      } else {
        console.error(res.data);
        alert(JSON.stringify(res.data, null, 2));
      }

      setTimeout(() => setDisableDispatch(false), DISPATCH_BUTTON_TIMEOUT_MS);
    };

    return (
      <Button
        variant='contained'
        disabled={
          selectedStop === undefined || vehicle === undefined || disableDispatch
        }
        size='large'
        onClick={onClick}
      >
        {text}
      </Button>
    );
  };

  const getDemoButton = () => {
    return getButton(
      DispatchMode.demo,
      'Go Here',
      BottomSheetButtonType.GO_HERE
    );
  };

  const getTestPickupButton = () => {
    return getButton(
      DispatchMode.riderExperience,
      'Pick-Up',
      BottomSheetButtonType.PICKUP
    );
  };

  const getTestDropoffButton = () => {
    return getButton(
      DispatchMode.riderExperience,
      'Drop-Off',
      BottomSheetButtonType.DROPOFF
    );
  };

  const getCancelButton = () => {
    if (vehicle === undefined || !vehicle.nextStop) return;
    return (
      <Button
        variant='contained'
        disabled={
          selectedStop === undefined ||
          vehicle === undefined ||
          disableCancel ||
          !vehicle.odsRideId
        }
        size='large'
        onClick={onCancelClick}
        color='warning'
      >
        Cancel
      </Button>
    );
  };

  return (
    <Paper
      sx={{
        width: 'calc(var(--vw, 1vw) * 100)',
        height: '20vh',
        margin: '0 auto',
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        boxSizing: 'border-box'
      }}
      elevation={2}
    >
      <Typography>{getContent()}</Typography>
      {getDemoButton()}
      {getTestPickupButton()}
      {getTestDropoffButton()}
      {getCancelButton()}
    </Paper>
  );
};

export default BottomSheet;
