import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  NativeSelect,
  Switch,
  Typography
} from '@mui/material';
import routes from './routes.json';
import { addVehicleNames, setVehicleUpdates } from './redux/actions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import {
  getVehicles,
  getVehicleProvidedRoute,
  getVehicleDisplayNames
} from './requests';
import { DispatchMode, Vehicle, VehicleNameState, VehicleRoute } from './type';
import MayLogo from './img/may_logo_green.png';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { MapProps } from './Map';

const getVehicleRoutes = (): string[] => routes.map((route) => route.name);
const ROUTE_NAMES = getVehicleRoutes();

const removeExcludedStops = (
  vehicleRouteToUpdate: VehicleRoute,
  selectedStopsToCheck: AvailableStop[]
): void => {
  vehicleRouteToUpdate.stops = vehicleRouteToUpdate.stops.filter(
    (routeStop, index) => {
      //The names should be the same by index
      //because both arrays were created in the same order,
      //but rechecking here just in case there is a data error.
      if (routeStop.stopName !== selectedStopsToCheck[index].stopName) {
        console.error(
          'Stop names at index ',
          index,
          ' do not match. Expected: ',
          routeStop.stopName,
          ' but received',
          selectedStopsToCheck[index].stopName
        );
        return false;
      }

      return selectedStopsToCheck[index].isChecked;
    }
  );
};

interface HomeProps {
  setMapProps: Dispatch<SetStateAction<MapProps | undefined>>;
}

interface AvailableStop {
  stopId: string;
  stopName: string;
  isChecked: boolean;
}

const Home: React.FC<HomeProps> = ({ setMapProps }) => {
  const history = useHistory();
  const [mayId, setMayId] = useState('');
  const [routeName, setRouteName] = useState(ROUTE_NAMES[0]);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [features, setFeatures] = useState<string[]>([]);
  const [stopIds, setStopIds] = useState<string[]>([]);
  //vehicleStops come from the vehicle, not the routes.json
  const [vehicleProvidedRoute, setVehicleProvidedRoute] = useState<
    VehicleRoute | undefined
  >(undefined);
  const [routeSelectedStops, setRouteSelectedStops] = useState<AvailableStop[]>(
    []
  );

  const vehicleNameState: VehicleNameState = useAppSelector((state) => {
    return state.vehicleNames;
  });

  const [showRouteStops, setShowRouteStops] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const availableMayIds = useAppSelector((state) => {
    return Object.values(state.vehicles)
      .map((currentVehicle) => ({
        id: currentVehicle.vehicleId,
        name:
          vehicleNameState[currentVehicle.vehicleId] !== undefined
            ? vehicleNameState[currentVehicle.vehicleId]
            : currentVehicle.vehicleId
      }))
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  });

  const updateVehicleNameState = async (token: string, vehicles: Vehicle[]) => {
    const vehicleIdsWithoutNames = vehicles
      .filter(
        (currentVehicle) =>
          vehicleNameState[currentVehicle.vehicleId] === undefined
      )
      .map((currentVehicle) => currentVehicle.vehicleId);

    if (vehicleIdsWithoutNames.length > 0) {
      const vehicleDisplayNames = await getVehicleDisplayNames(
        vehicleIdsWithoutNames,
        token
      );
      dispatch(addVehicleNames(vehicleDisplayNames));
    }
  };

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchData = async () => {
      try {
        const session = await Auth.currentSession();
        if (!isMounted) return;

        const token = session.getAccessToken().getJwtToken();
        setAccessToken(token);

        const sessionFeatures = session.getAccessToken().payload.features || [];
        setFeatures(sessionFeatures);

        const route = session.getAccessToken().payload.route;
        if (route && !route.includes('all')) {
          setRouteName(route);
        }

        const mayIdForExternalUser = session.getAccessToken().payload.vehicle;
        if (mayIdForExternalUser && !mayIdForExternalUser.includes('all')) {
          setMayId(mayIdForExternalUser);
        }

        const stopIdsString = session.getAccessToken().payload.stops;
        setStopIds(
          stopIdsString && !stopIdsString.includes('all')
            ? stopIdsString.split(',')
            : []
        );
        if (sessionFeatures.includes('demo') && !mayIdForExternalUser) {
          console.error('No vehicle assigned for external user.');
        }

        if (sessionFeatures.includes('demo')) {
          setMapProps({
            mayId: mayIdForExternalUser,
            routeName,
            dispatchMode: DispatchMode.demo,
            stopIds,
            vehicleProvidedRoute
          });
          history.push('/demo');
        } else if (sessionFeatures.includes('rx')) {
          setMapProps({
            mayId: mayIdForExternalUser,
            routeName,
            dispatchMode: DispatchMode.riderExperience,
            stopIds,
            vehicleProvidedRoute
          });
          history.push('/rx');
        } else {
          history.push('/');
        }

        if (
          token &&
          !sessionFeatures.includes('demo') &&
          !sessionFeatures.includes('rx')
        ) {
          const vehicles = await getVehicles(token);
          await updateVehicleNameState(token, vehicles);
          if (isMounted) {
            dispatch(setVehicleUpdates(vehicles));
          }
        }
      } catch (error) {
        console.error('Error getting access token', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function sets isMounted to false
    };
  }, [history, setMapProps, mayId, routeName, dispatch, routeSelectedStops]);

  useEffect(() => {
    const fetchVehicleStopsData = async () => {
      try {
        const session = await Auth.currentSession();

        const token = session.getAccessToken().getJwtToken();
        setAccessToken(token);

        if (mayId !== '') {
          const vehicleProvidedRouteData = await getVehicleProvidedRoute(
            mayId,
            token
          );
          setVehicleProvidedRoute(vehicleProvidedRouteData);

          const routeSelectedStopsData: AvailableStop[] = [];

          if (vehicleProvidedRouteData !== undefined) {
            vehicleProvidedRouteData.stops.map((el) => {
              routeSelectedStopsData.push({
                stopId: vehicleProvidedRouteData.routeName + '-' + el.stopName,
                stopName: el.stopName,
                isChecked: true
              });
            });
            setRouteSelectedStops(routeSelectedStopsData);
            setRouteName(vehicleProvidedRouteData.routeName);
          } else {
            setRouteSelectedStops([]);
          }
        } else {
          setVehicleProvidedRoute(undefined);
          setRouteSelectedStops([]);
        }
      } catch (error) {
        setVehicleProvidedRoute(undefined);
        setRouteSelectedStops([]);

        console.error('Error in fetchVehicleStopsData', error);
      }
    };

    fetchVehicleStopsData();
  }, [mayId]);

  const handleSelectedStopChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedStops = routeSelectedStops.map((currentStop) => {
      if (currentStop.stopName === event.target.name) {
        currentStop.isChecked = event.target.checked;
      }
      return currentStop;
    });
    setRouteSelectedStops(selectedStops);
  };

  const handleShowRouteStopsChange = () => {
    setShowRouteStops((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: 'calc(var(--vw, 1vw) * 100)',
        margin: 'auto',
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        rowGap: '12px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={MayLogo} width='240px' alt='May Logo' />
      </Box>
      <FormControl fullWidth>
        <InputLabel variant='standard' htmlFor='controlled-native-id-select'>
          May ID
        </InputLabel>
        <NativeSelect
          value={mayId}
          inputProps={{
            name: 'mayId',
            id: 'controlled-native-id-select'
          }}
          onChange={(e) => setMayId(e.target.value)}
        >
          <option value={''} />
          {availableMayIds.map((mayId) => (
            <option value={mayId.id} key={mayId.id}>
              {mayId.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel variant='standard' htmlFor='controlled-native-route-select'>
          Route
        </InputLabel>
        <NativeSelect
          value={routeName}
          inputProps={{
            name: 'route',
            id: 'controlled-native-route-select'
          }}
          onChange={(e) => setRouteName(e.target.value)}
        >
          {vehicleProvidedRoute !== undefined && (
            <option
              value={vehicleProvidedRoute.routeName}
              key={vehicleProvidedRoute.routeName}
            >
              Current shift route ({vehicleProvidedRoute.routeName})
            </option>
          )}
          {ROUTE_NAMES.sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase())
          ).map((name) => (
            <option value={name} key={name}>
              {name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <Box>
        {routeSelectedStops.length > 0 &&
          routeName === vehicleProvidedRoute?.routeName && (
            <FormControl
              component='fieldset'
              variant='standard'
              style={{ paddingLeft: '10px' }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={showRouteStops}
                    onChange={handleShowRouteStopsChange}
                  />
                }
                label={
                  showRouteStops
                    ? 'Click to hide stops for route.'
                    : 'Click to select stops for route.'
                }
                style={{ backgroundColor: 'lightgray', marginRight: '0px' }}
              />
              <Collapse in={showRouteStops}>
                <FormGroup
                  row
                  style={{ overflowY: 'auto', maxHeight: '200px' }}
                >
                  {routeSelectedStops.map((vehicleStop) => (
                    <FormControlLabel
                      style={{ width: '200px' }}
                      key={vehicleStop.stopId}
                      control={
                        <Checkbox
                          checked={vehicleStop.isChecked}
                          onChange={handleSelectedStopChange}
                          name={vehicleStop.stopName}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={vehicleStop.stopName}
                    />
                  ))}
                </FormGroup>
              </Collapse>
            </FormControl>
          )}
      </Box>
      <Button
        variant='contained'
        disabled={mayId === ''}
        size='large'
        onClick={() => {
          if (mayId !== '') {
            if (vehicleProvidedRoute !== undefined) {
              removeExcludedStops(vehicleProvidedRoute, routeSelectedStops);
            }
            setMapProps({
              mayId,
              routeName,
              dispatchMode: DispatchMode.demo,
              stopIds,
              vehicleProvidedRoute
            });
            history.push('/demo');
          }
        }}
      >
        Demo Mode
      </Button>
      <Button
        variant='contained'
        disabled={mayId === ''}
        size='large'
        onClick={() => {
          if (mayId !== '') {
            if (vehicleProvidedRoute !== undefined) {
              removeExcludedStops(vehicleProvidedRoute, routeSelectedStops);
            }

            setMapProps({
              mayId,
              routeName,
              dispatchMode: DispatchMode.riderExperience,
              stopIds,
              vehicleProvidedRoute
            });
            history.push('/rx');
          }
        }}
      >
        Rx Mode
      </Button>
      <Box sx={{ display: 'inline', float: 'left' }}>
        <Typography variant='h4'>Notes for use:</Typography>
        <ul>
          <li>The vehicle must be on the allow list to work with this tool.</li>
          <li>
            The vehicle must be running a non-production shift to appear in the
            dropdown.
          </li>
          <li>
            Select the route and vehicle, then press `Demo Mode` (Pickup
            commands only) or `Rx Mode` (Pickup and drop-off commands).
          </li>
        </ul>
        <Typography>
          More information can be found{' '}
          <a
            href='https://maymobility.atlassian.net/wiki/spaces/RE/pages/2698575873/User+Doc+Internal+May+ODS+Dispatcher+-+For+Demos+Testing'
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
